import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function Myplans() {
  const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(true)

  /// use profits to display earnings too and also
  const [profits, setprofits] = useState(0)

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
      setOpen(false);
  };
  const handleToggle = () => {
      setOpen(!open);
  };

  function cleanDate(d) {
      var date = new Date(d);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      console.log("Date: " + date.getDate() +
          "/" + (months[date.getMonth() + 1]) +
          "/" + date.getFullYear() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds());
      return `${date.getDate()} ${(months[date.getMonth()])} ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`
  }

  const navigate = useNavigate();
  useEffect(() => {
      if (loggedin) {
          console.log(userdetails);
          console.log(userdetails.email);
          setOpen(!open);
          planduecheck(userdetails)
      } else {
          f.auth().onAuthStateChanged(function (user) {
              if (user) {
                  var userid = f.auth().currentUser;
                  var userids = userid.uid;
                  fetchuserdata(userids);
                  setloggedin(true);
                  setloading(false)

              } else {
                  setloggedin(false);
                  setOpen(!open);
                  navigate("/");
              }
          });
      }
  }, []);


  const fetchuserdata = async (userid) => {
      var docRef = db.collection("users").doc(userid);
      await docRef
          .get()
          .then(function (doc) {
              if (doc.exists) {
                  setdetails(doc.data());
                  console.log(doc.data())
                  setloggedin(true)
                  setOpen(!open);
                  planduecheck(doc.data())
              } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
              }
          })
          .catch(function (error) {
              console.log("Error getting document:", error);
          });
  };


  const setdetails = (data) => {
      dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
      dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
      const let1 = await setloggedin(false);
      const let2 = await f.auth().signOut();
      const let3 = await navigate("/");
  };

  function addHoursToDate(date, hours) {
      return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const planduecheck = (info) => {
      const d1 = new Date();
      if (info.currentSubscription != null) {
          // && info.currentSubscription.dueDate !==null
          if (d1.getTime() >= info.currentSubscription.dueDate) {
              //plan matured send mail to admin and increment acountball with due bal
              // set info.currentSubscription to null in firebase
              const newBal =
                  parseInt(info.balance) +
                  parseInt(info.currentSubscription.dueAmount);

              updateUserBalanceandSub(newBal, info.currentSubscription.dueAmount);
              console.log(info.currentSubscription.dueDate);
          } else if (d1.getTime() < info.currentSubscription.dueDate) {

              var today = new Date();
              var total = info.currentSubscription.dueDate - info.currentSubscription.dateSubscribed;
              var progress = today - info.currentSubscription.dateSubscribed;

              console.log(Math.round(progress / total * 100) + "%");
              const currentprofit = ((progress / total * 100) * info.currentSubscription.dueAmount) / 100
              console.log(currentprofit)
              intrestprogress(currentprofit)
              setprofits(currentprofit)

              console.log(info.currentSubscription.dueDate - d1.getTime());
              //plan not yet matured show current progress of app
              const planprogress =
                  ((info.currentSubscription.dueDate - d1.getTime()) /
                      info.currentSubscription.dueDate) *
                  100;
              console.log(planprogress);
              let date = new Date(info.currentSubscription.dueDate);
              console.log(date.toString());
          }
      } else {
          console.log(info.email);
          //show invest button
      }
  };


  const intrestprogress = (bal) => {
      var userid = f.auth().currentUser;
      var userids = userid.uid;
      var washingtonRef = db.collection("users").doc(userids);
      const increment = firebase.firestore.FieldValue.increment(parseInt(bal));
      washingtonRef
          .update({
              totalearnings: bal,
          })
          .then(function () {
              console.log("Document successfully updated!");
          })
          .catch(function (error) {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
          });
      // fetchuserdata(userids);
  };

  const updateUserBalanceandSub = (bal, addedbal) => {
      var userid = f.auth().currentUser;
      var userids = userid.uid;
      var washingtonRef = db.collection("users").doc(userids);
      washingtonRef
          .update({
              balance: bal,
              currentSubscription: null,
              totalearnings: addedbal,
          })
          .then(function () {
              console.log("Document successfully updated!");
          })
          .catch(function (error) {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
          });
      // fetchuserdata(userids);
  };

  return (
    <div>
    <div className="container-fluid container-application">
      {/* Sidenav */}
  <Nav/>
      {/* Content */}
      <div className="main-content position-relative">
        {/* Main nav */}
        {/* Main nav */}
        <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
          <div className="container-fluid">
            {/* Brand + Toggler (for mobile devices) */}
            <div className="pl-4 d-block d-md-none">
              <a className="navbar-brand" href="/dashboard">
                <img src="/assets/images/logo.png" className="navbar-brand-img" alt="..." />
              </a>
            </div>
            {/* User's navbar */}
            <div className="ml-auto navbar-user d-lg-none">
              <ul className="flex-row navbar-nav align-items-center">
                <li className="nav-item">
                  <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                </li>
                <li className="nav-item dropdown dropdown-animate">
                  <a className="nav-link nav-link-icon" href="#">
                    <i className="fas fa-user-check" />
                    <strong style={{fontSize: '8px'}}>Verified</strong>
                  </a>
                </li>
                <li className="nav-item dropdown dropdown-animate">
                  <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="avatar avatar-sm rounded-circle">
                      <i className="fas fa-user-circle fa-2x" />
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                    <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                    <a href="/dashboard/account-settings" className="dropdown-item">
                      <i className="far fa-user" />
                      <span>My profile</span>
                    </a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                    document.getElementById('logout-form').submit();">
                      <i className="far fa-sign-out-alt" />
                      <span>Logout</span>
                    </a>
                    <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{display: 'none'}}>
                      <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                    </form>
                  </div>
                </li>
              </ul>
            </div>
            {/* Navbar nav */}
            <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
              {/* Right menu */}
              <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                <li className="nav-item">
                  <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                </li>
                <li className="nav-item dropdown dropdown-animate">
                  <a className="nav-link nav-link-icon" href="#">
                    <i className="fas fa-user-check" />
                    <strong style={{fontSize: '8px'}}>Verified</strong>
                  </a>
                </li>
                <li className="nav-item dropdown dropdown-animate">
                  <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="media media-pill align-items-center">
                      <span className="avatar rounded-circle">
                        <i className="fas fa-user-circle fa-2x" />
                      </span>
                      <div className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">{userdetails.fullname}</span>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                    <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                    <a href="/dashboard/account-settings" className="dropdown-item">
                      <i className="far fa-user" />
                      <span>My profile</span>
                    </a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                    document.getElementById('logout-form').submit();">
                      <i className="far fa-sign-out-alt" />
                      <span>Logout</span>
                    </a>
                    <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{display: 'none'}}>
                      <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* Page content */}
        <div className="page-content">
          {/* Page title */}
          <div className="page-title">
            <div className="row justify-content-between align-items-center">
              <div className="mb-3 col-md-6 mb-md-0">
                <h5 className="mb-0 text-white h3 font-weight-400">My Investment plans
                  (All)</h5>
              </div>
            </div>
          </div>
          <div>
          </div>    <div>
          </div>    <div className="row">
            {
              userdetails.currentSubscription==null &&(
                <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="mt-4 row">
                      <div className="col-md-12">
                        <div className="py-4 card">
                          <div className="text-center card-body">
                            <p>You do not have an investment plan at the moment or no value match your query.
                            </p>
                            <a href="/TradingPlans" className="px-3 btn btn-primary">Buy a plan</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
            }
              {
              userdetails.currentSubscription!=null &&(
                <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <p>Your  Current Investment Details</p>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <p className="mb-0 small">Name of plan</p>
                        <span className="text-primary small">{userdetails.currentSubscription.currentPlan}</span>
                      </div>
                      <div className="mb-3 col-md-6">
                        <p className="mb-0 small">Current Profit</p>
                        <span className="text-primary small">{profits} </span>
                      </div> 
                      <div className="mb-3 col-md-6">
                        <p className="mb-0 small">Plan Price</p>
                        <span className="text-primary small">${userdetails.currentSubscription.amount}</span>
                      </div>
                      <div className="mb-3 col-md-6">
                        <p className="mb-0 small">Expiry Date</p>
                        <span className="text-primary small">{} {new Date(userdetails.currentSubscription.dueDate).toDateString()}</span>
                      </div>                
                      <div className="mb-3 col-md-6">
                        <p className="mb-0 small">Maximum Return</p>
                        <span className="text-primary small">${userdetails.currentSubscription.dueAmount}</span>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div>
              )
            }
       


          </div>
        </div>
        {/* Footer */}
        <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
          <div className="text-center row text-sm-left align-items-sm-center">
            <div className="col-sm-6">
              <p className="mb-0 text-sm">All Rights Reserved © Tradelitepro
                2023</p>
            </div>
            <div className="text-right col-sm-6 text-md-center">
              <div id="google_translate_element" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Scripts */}
    {/* Core JS - includes jquery, bootstrap, popper, in-view and sticky-kit */}
    {/* Bootstrap Notify */}
    {/* Page JS */}
    {/* Purpose JS */}
    {
                loading && (
                  <div id="backdrop">
                  <div class="text-center loading">
                      <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
                </div>
                    
                )
            }
  </div>
  )
}

export default Myplans