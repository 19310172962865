import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
function Transactions() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [dataready, setdataready] = useState(false)


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
          setdataready(true)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  return (
    <div>
      <div className="container-fluid container-application">
        {/* Sidenav */}
        <Nav />
        {/* Content */}
        <div className="main-content position-relative">
          {/* Main nav */}
          {/* Main nav */}
          <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
            <div className="container-fluid">
              {/* Brand + Toggler (for mobile devices) */}
              <div className="pl-4 d-block d-md-none">
                <a className="navbar-brand" href="/dashboard">
                  <img src="/assets/images/logo.png" className="navbar-brand-img" alt="..." />
                </a>
              </div>
              {/* User's navbar */}
              <div className="ml-auto navbar-user d-lg-none">
                <ul className="flex-row navbar-nav align-items-center">
                  <li className="nav-item">
                    <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="fas fa-user-check" />
                      <strong style={{ fontSize: '8px' }}>Verified</strong>
                    </a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="avatar avatar-sm rounded-circle">
                        <i className="fas fa-user-circle fa-2x" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                      <a href="/dashboard/account-settings" className="dropdown-item">
                        <i className="far fa-user" />
                        <span>My profile</span>
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                    document.getElementById('logout-form').submit();">
                        <i className="far fa-sign-out-alt" />
                        <span>Logout</span>
                      </a>
                      <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Navbar nav */}
              <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                {/* Right menu */}
                <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                  <li className="nav-item">
                    <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="fas fa-user-check" />
                      <strong style={{ fontSize: '8px' }}>Verified</strong>
                    </a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="media media-pill align-items-center">
                        <span className="avatar rounded-circle">
                          <i className="fas fa-user-circle fa-2x" />
                        </span>
                        <div className="ml-2 d-none d-lg-block">
                          <span className="mb-0 text-sm font-weight-bold">{userdetails.fullname}</span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                      <a href="/dashboard/account-settings" className="dropdown-item">
                        <i className="far fa-user" />
                        <span>My profile</span>
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                    document.getElementById('logout-form').submit();">
                        <i className="far fa-sign-out-alt" />
                        <span>Logout</span>
                      </a>
                      <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* Page content */}
          <div className="page-content">
            {/* Page title */}
            <div className="page-title">
              <div className="row justify-content-between align-items-center">
                <div className="mb-3 col-md-6 mb-md-0">
                  <h5 className="mb-0 text-white h3 font-weight-400">Your ROI history</h5>
                </div>
              </div>
            </div>
            <div>
            </div>    <div>
            </div>    <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-5 row">
                      <div className="p-2 text-center p-md-4 col-12">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead className="font-weight-bold bg-light">
                              <tr>
                                <th>Plan</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Date created</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                dataready && (
                                  userdetails.Withdrawalhistory.map((obj, i) => (
                                    <tr>
                                      <td>{obj.mode}</td>
                                      <td>${obj.amt}
                                      </td>
                                      <td>{obj.coin}</td>
                                      <td>{new Date(obj.date).toLocaleString()}
                                      </td>
                                    </tr>

                                  ))

                                )
                              }
                              {
                                dataready && (
                                  userdetails.Deposithistory.map((obj, i) => (
                                    <tr>
                                      <td>{obj.mode}</td>
                                      <td>${obj.amt}
                                      </td>
                                      <td>{obj.coin}</td>
                                      <td>{new Date(obj.date).toLocaleString()}
                                      </td>
                                    </tr>

                                  ))

                                )
                              }

                            </tbody>
                          </table>
                        </div>
                        <nav>
                       
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
            <div className="text-center row text-sm-left align-items-sm-center">
              <div className="col-sm-6">
                <p className="mb-0 text-sm">All Rights Reserved © Tradelitepro
                  2023</p>
              </div>
              <div className="text-right col-sm-6 text-md-center">
                <div id="google_translate_element" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Scripts */}
      {/* Core JS - includes jquery, bootstrap, popper, in-view and sticky-kit */}
      {/* Bootstrap Notify */}
      {/* Page JS */}
      {/* Purpose JS */}
      {
                loading && (
                  <div id="backdrop">
                  <div class="text-center loading">
                      <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
                </div>
                    
                )
            }
    </div>
  )
}

export default Transactions